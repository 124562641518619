<template>
  <div>
    <div>
      <section class="mb-3">
        <section class="d-flex justify-content-between align-items-center">
          <div class="input-search col-md-5 p-0">
            <b-form-input
              v-model="search"
              @input="handleSearch"
              placeholder="Digite para pesquisar"
              class="mb-2"
            />
            <b-button
              variant="light"
              class="px-2 d-flex align-items-center absolute icon-action"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>
          <b-button class="btn-add mb-0" @click="handleOpenModalInsurance()">
            Adicionar
          </b-button>
        </section>
      </section>
      <cards-list grid="3" class="mb-3" gap="12">
        <b-card v-for="(insurance, index) in items" :key="index">
          <div class="d-flex justify-content-end mb-3">
            <section
              v-if="insurance.insurance && insurance.insurance.social_reason"
            >
              <strong class="d-block">
                {{ insurance.insurance.social_reason }}
              </strong>
              <span>
                {{ insurance.insurance.cnpj | VMask("##.###.###/####-##") }}
              </span>
            </section>
            <div
              class="
                action
                icon-action
                d-flex
                justify-content-between
                align-items-start
                gap-2
              "
            >
              <b-icon
                icon="pencil-square"
                class="pointer mr-2 h6"
                @click="handleEdit(insurance)"
              />
              <b-icon
                icon="trash"
                @click="handleConfirmDelete(insurance)"
                class="pointer h6"
              />
            </div>
          </div>
          <span class="d-block">
            Tipo:
            <strong class="d-block">
              {{
                insurance.type 
              }}
            </strong>
          </span>
          <br>
          <span class="d-block">
            Apólice:
            <strong class="d-block">
              {{
                insurance.policy_num | VMask("#####.####.####-####-####-#####")
              }}
            </strong>
          </span>
          <span class="d-block">
            <span class="d-block mt-2"> Cobertura:</span>
            {{ insurance.coverage | money }}
          </span>
          <span v-if="insurance.insurance_files">
            Comprovantes:

            <b-button
              class="m-0 mt-2 pl-0 d-flex align-items-center"
              download="proposed_file_name"
              size="sm"
              variant="link"
              target="_blank"
              :title="insurance.insurance_files[0].file"
              :href="insurance.insurance_files[0].file"
            >
              <span class="file-insurance">
                {{ getName(insurance.insurance_files[0].file) }}
              </span>
              <b-icon icon="download" class="ml-2" />
            </b-button>
          </span>
        </b-card>
      </cards-list>
      <empty-list v-if="empty" text="Nenhuma informação encontrada">
      </empty-list>
      <b-pagination
        v-else
        @change="handleChangePage"
        v-model="current_page"
        :total-rows="total"
        :per-page="per_page"
        aria-controls="my-table"
      />
    </div>

    <b-modal
      id="add-insurance"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`${isAdd ? 'Adicionar seguro' : 'Editar seguro'}`"
    >
      <Form :id="selectedId" :isAdd="isAdd" @submited="handleCloseModal" />
    </b-modal>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";
import Form from "./form.vue";

export default {
  mixins: [listPaginated],

  components: {
    Form,
  },
  data() {
    return {
      action_name: "insurance_providers",
      isAdd: false,
      empty: false,
      selectedInsurance: { id: "" },
      selectedId: null,
      search: "",
      insurances: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["show_delete", "delete_insurance_provider"]),

    getName(file) {
      const splited = file.split("/");
      return splited[splited.length - 1];
    },

    handleConfirmDelete(item) {
      this.selectedInsurance = item;
      this.selectedId = this.selectedInsurance.id;
      this.show_delete({
        in_delete: true,
      });
    },
    handleEdit(insurance) {
      this.isAdd = false;
      this.selectedInsurance = insurance;
      this.selectedId = insurance.id;
      this.$bvModal.show("add-insurance");
    },
    handleOpenModalInsurance() {
      this.isAdd = true;
      this.selectedId = this.selectedInsurance.id;
      this.$bvModal.show("add-insurance");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-insurance");
      this.getList();
    },
  },
  created() {
    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.delete_insurance_provider({ id: this.selectedId }).then(() => {
          this.getList();
          this.show_delete({
            in_delete: false,
          });
        });
      }
    });
  },
  watch: {
    items(arr) {
      this.empty = arr.length === 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-insurance {
  max-width: 240px;
  min-width: 240px;
}
.file-insurance {
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
